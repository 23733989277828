import React, { useState, useEffect } from 'react';
import { storage } from '../../firestore.js';
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from 'firebase/storage';
import { X, Download, Upload, Paperclip } from 'lucide-react';

const FileUpload = ({ uid, convoUserId, postId }) => {
  const [files, setFiles] = useState([]);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 1MB in bytes

  useEffect(() => {
    const fetchFiles = async () => {
      const listRef = ref(storage, `artifacts/${convoUserId}/${postId}`);
      const fileList = await listAll(listRef);
      const filesData = await Promise.all(
        fileList.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return { name: item.name, url, fullPath: item.fullPath };
        })
      );
      setFiles(filesData);
    };
    fetchFiles();
  }, [uid, convoUserId, postId]);

  const handleFileUpload = async (event) => {
    if (!uid) {
      console.error('No user logged in');
      return;
    }

    const file = event.target.files[0];
    if (file && postId) {
      if (file.size > MAX_FILE_SIZE) {
        console.error('File is too large. Maximum size allowed is 1MB.');
        return;
      }

      const filePath = `artifacts/${convoUserId}/${postId}/${file.name}`;
      const storageRef = ref(storage, filePath);
      try {
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        setFiles([...files, { name: file.name, url, fullPath: filePath }]);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const removeFile = async (file) => {
    if (!uid) {
      console.error('No user logged in');
      return;
    }

    const fileRef = ref(storage, file.fullPath);
    try {
      await deleteObject(fileRef);
      setFiles(files.filter((f) => f.name !== file.name));
      console.log('File deleted successfully');
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={`${files.length > 0 ? 'border rounded-lg shadow-sm' : ''}`}>
      {files.length > 0 ? (
        <div className='flex items-center justify-between mb-4'>
          {uid === convoUserId ? (
            <h2 className='mr-2 text-lg font-semibold'>{files.length} files added</h2>
          ) : (
            <h2 className='mr-2 text-lg font-semibold'>{files.length} Artifacts</h2>
          )}
          {uid === convoUserId && files.length < 4 && (
            <label className='flex items-center px-4 py-2 bg-red-900 text-white rounded-lg cursor-pointer hover:bg-red-800'>
              <Upload className='mr-2' size={20} />
              Add artifacts
              <input type='file' className='hidden' onChange={handleFileUpload} />
            </label>
          )}
        </div>
      ) : (
        uid === convoUserId && (
          <label
            style={{ maxWidth: '200px' }}
            className='flex items-center px-4 py-2 bg-red-900 text-white rounded-lg cursor-pointer hover:bg-red-800'
          >
            <Upload className='mr-2' size={20} />
            Add artifacts
            <input type='file' className='hidden' onChange={handleFileUpload} />
          </label>
        )
      )}
      {files.length > 0 && (
        <div className='grid grid-cols-2 gap-4'>
          {files.map((file) => (
            <div key={file.name} className='relative p-8 border rounded-lg flex items-center'>
              <Paperclip className='mr-2' size={20} />
              <span className='flex-grow truncate'>{file.name}</span>
              <button
                style={{ height: '21px', width: '21px' }}
                onClick={() => downloadFile(file)}
                className='absolute top-1 right-10 p-0 rounded-full hover:bg-gray-200'
                title='Download file'
              >
                <Download style={{ marginBottom: '5px' }} size={12} />
              </button>
              {uid == convoUserId && (
                <button
                  style={{ height: '21px', width: '21px' }}
                  onClick={() => removeFile(file)}
                  className='absolute top-1 right-1 p-0 rounded-full hover:bg-gray-200'
                >
                  <X style={{ marginBottom: '5px' }} size={12} />
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
