import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DeleteConvo = ({ uid, convoUserId, postId, setPassAlert }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`https://aiarchives-375517.uc.r.appspot.com/api/deletePost/${postId}/${uid}`, {
        method: 'POST',
      });

      if (response.ok) {
        setPassAlert('Conversation deleted successfully.');
        navigate('/');
      } else {
        throw new Error('Failed to delete conversation');
      }
    } catch (error) {
      setPassAlert('Error: Failed to delete conversation. Please try again.');
    }
    handleCloseDialog();
  };

  return (
    <>
      {uid === convoUserId && (
        <div onClick={handleOpenDialog} className='cursor-pointer flex items-center'>
          <Trash2 className='mr-2' size={20} />
          Delete Conversation
        </div>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this conversation? This action cannot be reversed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteConvo;
