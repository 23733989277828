import React, { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { firebase_auth } from '../../firestore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import { BootstrapDialog, BootstrapDialogTitle } from '../Modals/CustomModal';
import { Button, DialogActions, DialogContent, FormControl, FormLabel, TextField, Input } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMediaQuery } from 'react-responsive';
import JSBI from 'jsbi';

const useStyles = makeStyles({
  selectedRow: {
    backgroundColor: '#ef53501f !important', // change this to the color you want
  },
});

const colors = [
  '#781106',
  '#85281e',
  '#934037',
  '#a05850',
  '#ae7069',
  '#F96757',
  '#e05c4e',
  '#c75245',
  '#ae483c',
  '#953d34',
  '#7c332b',
  '#6b1e1e',
  '#de5c4e',
  '#B7B3A1',
];

const IconWithText = ({ icon, title, iconBackgroundColor, textColor = 'inherit' }) => {
  return (
    <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <Box
        style={{
          height: '40px',
          width: '40px',
          backgroundColor: iconBackgroundColor,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
      <Box>
        <Typography variant='body2' color={textColor}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

const CreateFolder = (props) => {
  const {
    openMoveToDialog,
    manageFolder,
    setManageFolder,
    setOpenMoveToDialog,
    setFoldersChange,
    selected,
    setSelected,
    setPassAlert = () => {},
  } = props;
  const [profile, setProfile] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [folderName, setFolderName] = useState('');
  const [folders, _setFolders] = useState([]);
  const [editingFolder, setEditingFolder] = useState(null);
  const [editedFolderName, setEditedFolderName] = useState('');
  const [addFolderDialog, setAddFolderDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });
  const [folderCount, setFolderCount] = useState(0);
  const [openFolderCountSnackbar, setFolderCounttOpenSnackbar] = useState(false);

  const setFolders = (newFolders) => {
    _setFolders(newFolders.sort());
  };

  const handleFolderNameChange = (event) => {
    setFolderName(event.target.value);
  };

  const handleMoveToDialog = () => {
    setOpenMoveToDialog((prevState) => !prevState);
  };

  const handleAddToDialog = () => {
    setAddFolderDialog((prevState) => !prevState);
  };

  useEffect(() => {
    onAuthStateChanged(firebase_auth, (user) => {
      if (user) {
        setProfile({ uid: user.uid });
      } else {
        setProfile(false);
      }
    });
  }, [onAuthStateChanged, firebase_auth]);

  useEffect(() => {
    if (profile?.uid) {
      getFolders();
    }
  }, [profile?.uid]);

  const handleFolderCountSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFolderCounttOpenSnackbar(false);
  };

  const createFolder = () => {
    if (folderCount >= 10) {
      setFolderCounttOpenSnackbar(true);
    } else {
      fetch(`https://aiarchives-375517.uc.r.appspot.com/api/addFolder/${profile?.uid}/${folderName}`, {
        method: 'POST',
      }).then(async (res) => {
        setAlertOpen(true);
        setAlertMessage('Folder successfully created!');
        setPassAlert('Folder successfully created!');
        getFolders();
        handleAddToDialog();
        setFoldersChange((prev) => !prev);
      });
    }
  };

  const getFolders = () => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/getFoldersFromUser/${profile?.uid}`).then(async (res) => {
      const results = await res.json();
      setFolders(results?.folderNames);
      setFolderCount(results?.folderNames.length);
    });
  };

  const addToFolder = (folder) => {
    console.log(selected, folder);
    if (selected.length) {
      fetch(
        `https://aiarchives-375517.uc.r.appspot.com/api/addPostToFolder/${profile?.uid}/${folder}/${selected.join(
          '_'
        )}`,
        { method: 'POST' }
      ).then(async (res) => {
        setAlertOpen(true);
        setAlertMessage(`Added to ${folder} successfully!`);
        setPassAlert(`Added to ${folder} successfully!`);
        handleMoveToDialog();
        setSelected([]);
      });
    }
  };

  const handleDeleteOpen = (folder) => {
    setFolderToDelete(folder);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function fnv1a(text) {
    let hash = JSBI.BigInt(2166136261);
    for (let i = 0; i < text.length; i++) {
      hash = JSBI.bitwiseXor(hash, JSBI.BigInt(text.charCodeAt(i)));
      hash = JSBI.multiply(hash, JSBI.BigInt(16777619));
    }
    return JSBI.toNumber(JSBI.bitwiseAnd(hash, JSBI.BigInt(2147483647))); // Return a 31-bit number
  }

  const handleDelete = () => {
    fetch(`https://aiarchives-375517.uc.r.appspot.com/api/deleteFolder/${profile?.uid}/${folderToDelete}`, {
      method: 'POST',
    }).then(async (res) => {
      getFolders();
      setAlertMessage(`folder ${folderToDelete} deleted successfully!`);
      setFoldersChange((prev) => !prev);
    });
    setOpen(false);
  };

  const renameFolder = (oldFolderName) => {
    fetch(
      `https://aiarchives-375517.uc.r.appspot.com/api/renameFolder/${profile?.uid}/${oldFolderName}/${editedFolderName}`,
      { method: 'POST' }
    ).then(async (res) => {
      setEditingFolder(null);
      setEditedFolderName('');
      getFolders();
      setFoldersChange((prev) => !prev);
      setAlertMessage(`folder ${oldFolderName} renamed to ${editedFolderName} successfully!`);
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <BootstrapDialog
        onClose={handleMoveToDialog}
        aria-labelledby='customized-dialog-title'
        open={openMoveToDialog}
        fullScreen={isSmallScreen}
        fullWidth
        maxWidth='xs'
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleMoveToDialog}>
          <IconButton
            aria-label='close'
            onClick={handleMoveToDialog}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {manageFolder === 'all' || manageFolder === 'add' ? (
            <Button
              variant='primary'
              style={{
                color: 'white',
                fontSize: '0.8rem',
                backgroundColor: '#6b1e1e',
              }}
              onClick={handleAddToDialog}
            >
              Create new folder
            </Button>
          ) : (
            <Button
              variant='primary'
              style={{
                color: 'white',
                fontSize: '0.8rem',
                backgroundColor: '#fff',
              }}
            ></Button>
          )}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {manageFolder === 'all' ? (
              <FormLabel id='radio-buttons-folder-label'>Add/Edit/Remove Folders</FormLabel>
            ) : (
              <FormLabel id='radio-buttons-folder-label'>Copy Selected Posts to Folder</FormLabel>
            )}
            {folders.map((folder, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {editingFolder === index ? (
                  <>
                    <Input value={editedFolderName} onChange={(e) => setEditedFolderName(e.target.value)} />
                    <Button onClick={() => renameFolder(folder)}>Save</Button>
                  </>
                ) : (
                  <>
                    <Button
                      style={{
                        display: 'block',
                        width: manageFolder === 'all' ? '90%' : '100%',
                      }}
                      disabled={manageFolder === 'all'}
                      onClick={() => addToFolder(folder)}
                    >
                      <IconWithText
                        icon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='16'
                            fill='white'
                            class='bi bi-folder'
                            viewBox='0 0 16 16'
                          >
                            {' '}
                            <path d='M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z' />{' '}
                          </svg>
                        }
                        title={folder}
                        textColor='black'
                        iconBackgroundColor={colors[fnv1a(folder) % colors.length]}
                      />
                    </Button>
                    {manageFolder === 'all' ? (
                      <>
                        <Button
                          style={{ marginLeft: 'auto', minWidth: '25px' }}
                          title='Edit Folder'
                          onClick={() => {
                            setEditingFolder(index);
                            setEditedFolderName(folder);
                          }}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='15px'
                            width='15px'
                            fill='#9e9e9e'
                            class='bi bi-pencil-fill'
                            viewBox='0 0 16 16'
                          >
                            <path d='M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 11.207 7 9 4.793 11.207 2.5zm1.586 3L10.5 5.207 14.793 1 16 2.207 12.793 5.5zM1.92 13.175l-1.71.856 1.523-1.44-.067.752.254.253-.16-.421z' />
                          </svg>
                        </Button>
                        <Button
                          style={{ minWidth: '25px' }}
                          title='Delete Folder'
                          onClick={() => handleDeleteOpen(folder)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='20px'
                            width='20px'
                            fill='#9e9e9e'
                            class='bi bi-x'
                            viewBox='0 0 16 16'
                          >
                            <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
                          </svg>
                        </Button>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            ))}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              <DialogTitle id='alert-dialog-title'>{'Confirm Deletion'}</DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Are you sure you want to delete this folder?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleDelete} autoFocus>
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </FormControl>
        </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleAddToDialog}
        aria-labelledby='customized-dialog-title'
        open={addFolderDialog}
        fullWidth
        fullScreen={isSmallScreen}
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={handleAddToDialog}>
          <IconButton
            aria-label='close'
            onClick={handleAddToDialog}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          Create Folder
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography variant='body2' mb={1}>
            Folder Name
          </Typography>
          <TextField onChange={handleFolderNameChange} id='outlined-name' type='text' fullWidth />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              color: 'white',
              fontSize: '1rem',
              backgroundColor: '#6b1e1e',
            }}
            autoFocus
            onClick={() => createFolder()}
          >
            Create
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Snackbar
        open={alertOpen}
        autoHideDuration={2000}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity='success'
          variant='filled'
          elevation={6}
          sx={{ width: '100%' }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFolderCountSnackbar}
        autoHideDuration={3000}
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleFolderCountSnackbarClose}
      >
        <Alert onClose={handleFolderCountSnackbarClose} severity='error'>
          You have reached the folder limit of 10!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreateFolder;
