import React, { useState } from 'react';
import { FolderInput } from 'lucide-react';
import CreateFolder from '../CreateFolder/CreateFolder';

const SaveFolder = ({ uid, convoUserId, postId, setPassAlert }) => {
  const [openMoveToDialog, setOpenMoveToDialog] = useState(false);
  const [manageFolder, setManageFolder] = useState('add');
  const [foldersChange, setFoldersChange] = useState([]);
  const [selected, setSelected] = useState([]);

  return (
    <>
      {uid === convoUserId && (
        <div
          onClick={() => {
            setOpenMoveToDialog(true);
            setSelected([postId]);
            setManageFolder('add');
          }}
        >
          <FolderInput className='mr-2' size={20} />
          Save to Folder
        </div>
      )}
      <CreateFolder
        openMoveToDialog={openMoveToDialog}
        manageFolder={manageFolder}
        setManageFolder={setManageFolder}
        setOpenMoveToDialog={setOpenMoveToDialog}
        setFoldersChange={setFoldersChange}
        selected={selected}
        setSelected={setSelected}
        setPassAlert={setPassAlert}
      />
    </>
  );
};

export default SaveFolder;
